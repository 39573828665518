export const palette = {
	aquaBlue                 : "#00dbde",
	brownishGrey             : "#707070",
	algaeGreen               : "#21ba60",
	darkSeaGreen             : "#0c8165",
	darkModeDarkBlue50       : "rgba(18, 107, 213, 0.5)",
	darkModeDarkBlue10       : "rgba(18, 107, 213, 0.1)",
	darkModeDarkGrey         : "rgb(26, 26, 26)",
	darkModeDarkGrey20       : "rgba(117, 117, 117, 0.2)",
	darkModeInclinedBlack1   : "#272727",
	darkModeInclinedBlack2   : "#1a1a1a",
	boxShadowLight           : "rgb(0,0,0,0.2 ) 0px 31px 42px 8px",
	boxShadowDark            : "rgb(255 255 255 / 8%) 4px 4px 8px 0px",
	primaryLinearGradient    : "linear-gradient(to bottom, #126bd5 0%, #439aff 100%)",
	darkModeWhite20          : "rgba(255, 255, 255, 0.2)",
	darkModeWhite80          : "rgba(255, 255, 255, 0.8)",
	mainGradient             : "linear-gradient(to left, #439aff, #126bd5)",
	primaryDarkBlue          : "#126bd5",
	proGradient              : "linear-gradient(160deg, #00dbde,#6a8fef)",
	walletGradient           : "linear-gradient(160deg, #21ba60,#0c8165)",
	walletTextGradient       : "linear-gradient(to right, #21ba60 0%, #0c8165 100%)",
	primaryDarkBlue10        : "rgba(18, 107, 213, 0.1)",
	primaryLightBlue         : "#439aff",
	primaryRareBlue          : "#eef8ff",
	rightGradient            : "linear-gradient(to right, #439aff, #126bd5)",
	tabGradientRight         : "linear-gradient(to right, #00c5ff 0%, #0076ff 100%)",
	tabGradientBottom        : "linear-gradient(to bottom, #00c5ff 0%, #0076ff 100%)",
	secondaryBackgroundGrey  : "#f5f5f5",
	secondaryBlack           : "#000000",
	secondaryBlack25         : "#00000040",
	secondaryBlack50         : "rgba(0, 0, 0, 0.5)",
	primaryBackgroundGrey    : "",
	secondaryDarkGrey        : "#757575",
	secondaryDarkGrey50      : "rgba(117, 117, 117, 0.5)",
	secondaryLighterDarkGrey : "#858585",
	secondaryLightGrey       : "#e7e7e7",
	secondaryWhite           : "#ffffff",
	softBlue                 : "#6a8fef",
	tertiaryErrorRed         : "#e02020",
	tertiaryPink             : "#ff177e",
	proChipGradient          : "conic-gradient(#00DBDE00 10%, #00dbde 20%, #6a8fef 30%, #6A8FEF00 40%, #6A8FEF00 60%, #00dbde 70%, #6a8fef 80%, #00DBDE00 90%)",
	tertiarySuccessGreen     : "#56a700",
	tertiaryYellow           : "#f9a74e"
};

export const buttonsColorLight = {
	primaryBtn: {
		default: {
			text       : palette.secondaryWhite,
			background : palette.primaryLinearGradient
		},
		clicked: {
			text       : palette.secondaryWhite,
			background : palette.secondaryDarkGrey50
		},
		hover: {
			text       : palette.secondaryWhite,
			background : `linear-gradient(to bottom, ${ palette.primaryDarkBlue } 0%, ${ palette.primaryDarkBlue } 100%)`
		}
	},
	secondaryBtn: {
		default: {
			text       : palette.secondaryBlack,
			background : palette.secondaryBackgroundGrey,
			border     : palette.secondaryLightGrey
		},
		hover: {
			text       : palette.secondaryBlack,
			background : palette.secondaryLightGrey,
			border     : palette.secondaryLightGrey
		},
		clicked: {
			text       : palette.primaryDarkBlue,
			background : palette.primaryRareBlue,
			border     : palette.primaryDarkBlue
		}
	},
	tertiaryBtn: {
		default: {
			text       : palette.primaryDarkBlue,
			background : "transparent",
			border     : palette.primaryDarkBlue
		},
		hover: {
			text       : palette.primaryDarkBlue,
			background : palette.primaryRareBlue,
			border     : palette.primaryDarkBlue
		},
		clicked: {
			text       : palette.primaryDarkBlue,
			background : palette.primaryRareBlue,
			border     : palette.primaryDarkBlue
		}
	},
	textBtn: {
		default: {
			text       : palette.secondaryDarkGrey,
			background : "inherit"
		},
		disabled: {
			text       : palette.secondaryDarkGrey50,
			background : "inherit"
		},
		hover: {
			background: palette.darkModeDarkGrey20
		}
	}
};

export const buttonsColorDark = {
	primaryBtn: {
		default: {
			text       : palette.secondaryWhite,
			background : palette.primaryLinearGradient
		},
		clicked: {
			text       : palette.secondaryWhite,
			background : palette.secondaryDarkGrey50
		},
		hover: {
			text       : palette.secondaryWhite,
			background : `linear-gradient(to bottom, ${ palette.primaryDarkBlue } 0%, ${ palette.primaryDarkBlue } 100%)`
		}
	},
	secondaryBtn: {
		default: {
			text       : palette.secondaryLightGrey,
			// background : palette.darkModeDarkGrey,
			background : palette.darkModeDarkGrey20,
			border     : palette.darkModeWhite20
		},
		// same as default
		hover: {
			text       : palette.secondaryWhite,
			// background : palette.darkModeDarkGrey,
			background : palette.secondaryDarkGrey50,
			border     : palette.darkModeWhite20
		},
		clicked: {
			text       : palette.secondaryWhite,
			background : palette.darkModeDarkBlue50,
			border     : palette.primaryDarkBlue
		}
	},
	tertiaryBtn: {
		default: {
			text       : palette.primaryDarkBlue,
			background : "transparent",
			border     : palette.primaryDarkBlue
		},
		hover: {
			text       : palette.primaryDarkBlue,
			background : palette.darkModeDarkBlue10,
			border     : palette.primaryDarkBlue
		},
		clicked: {
			text       : palette.primaryDarkBlue,
			background : palette.darkModeDarkBlue50,
			border     : palette.primaryDarkBlue
		}
	},
	textBtn: {
		default: {
			text       : palette.secondaryDarkGrey,
			background : "inherit"
		},
		disabled: {
			text       : palette.secondaryDarkGrey50,
			background : "inherit"
		},
		hover: {
			background: palette.darkModeInclinedBlack1
		}
	}
};

export const light = {
	body: {
		primary   : palette.secondaryBackgroundGrey,
		secondary : palette.secondaryWhite
	},
	text: {
		webview   : palette.secondaryBlack,
		primary   : palette.secondaryBlack,
		secondary : palette.secondaryDarkGrey,
		tertiary  : palette.secondaryWhite
	},
	background: {
		primary   : palette.secondaryWhite,
		secondary : palette.secondaryWhite,
		tertiary  : palette.secondaryWhite
	},
	background2: {
		primary   : palette.secondaryBackgroundGrey,
		secondary : palette.secondaryBackgroundGrey
	},
	boxShadow: {
		primary: palette.boxShadowLight
	},
	border: {
		primary: palette.secondaryLightGrey
	},
	selectedState: {
		primary: palette.primaryDarkBlue10
	},
	input: {
		primary  : palette.secondaryLightGrey,
		disabled : palette.secondaryBackgroundGrey
	},
	inputPlaceHolder: {
		primary: palette.lightModeGreyPlaceholder
	},
	chip: {
		checked: {
			background : palette.primaryDarkBlue10,
			hover      : "initial",
			text       : palette.primaryDarkBlue,
			border     : palette.primaryDarkBlue
		},
		unchecked: {
			background : "transparent",
			text       : palette.secondaryDarkGrey,
			hover      : "transparent",
			border     : palette.secondaryLightGrey
		}
	},
	antSwitch: {
		checked: {
			background: palette.primaryLinearGradient
		},
		unchecked: {
			background: palette.secondaryLightGrey
		}
	},
	dialog   : { primary: palette.secondaryWhite, secondary: palette.secondaryWhite },
	skeleton : { primary: "rgb(33 33 33 / 25%)" },
	...buttonsColorLight
};

export const dark = {
	body: {
		primary   : palette.secondaryBlack,
		secondary : palette.secondaryBlack
	},
	text: {
		webview   : palette.secondaryWhite,
		primary   : palette.darkModeWhite80,
		secondary : palette.secondaryDarkGrey,
		tertiary  : palette.secondaryBlack
	},
	background: {
		primary   : palette.darkModeDarkGrey,
		secondary : palette.secondaryBlack,
		tertiary  : palette.darkModeInclinedBlack1
	},
	// for hover state
	background2: {
		primary   : palette.secondaryDarkGrey50,
		secondary : palette.secondaryBlack
	},
	boxShadow: {
		primary: palette.boxShadowDark
	},
	border: {
		primary: palette.darkModeWhite20
	},
	selectedState: {
		primary: palette.darkModeDarkBlue50
	},
	input: {
		primary  : palette.darkModeDarkGrey20,
		disabled : palette.darkModeInclinedBlack1
	},
	inputPlaceHolder: {
		primary: palette.darkModeWhite20
	},
	chip: {
		checked: {
			background : palette.darkModeDarkBlue50,
			hover      : "initial",
			border     : palette.primaryDarkBlue,
			text       : "initial"
		},
		unchecked: {
			background : palette.darkModeDarkGrey20,
			hover      : palette.secondaryDarkGrey50,
			text       : "inital",
			border     : palette.secondaryDarkGrey50
		}
	},
	antSwitch: {
		checked: {
			background: palette.primaryLinearGradient
		},
		unchecked: {
			background: palette.secondaryDarkGrey
		}
	},
	dialog   : { primary: palette.darkModeInclinedBlack1, secondary: palette.darkModeInclinedBlack2 },
	skeleton : { primary: "#212121" },
	...buttonsColorDark

};
